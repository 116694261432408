import React from "react";
import { NavLink } from "react-router-dom";
import logo from './star-of-david.png';


const Nav = () => {



  return (
    <div className="navbar-default container-fluid">
      <div className="row">
        <div className="col navbar-header">
        
        <a href="/" className="navbar-brand"><img src={logo} width="30px" alt="Star of David" /></a>
      </div>
      <div className="col">

      <Menu/>
      </div>
      </div>
    </div>
  );
}

/*
const Emoji = props => (
  <span
      className="emoji"
      role="img"

      aria-label={props.label ? props.label : ""}
      aria-hidden={props.label ? "false" : "true"}
  >
      {props.symbol}
  </span>);

*/

const Menu = props => {
 
  return (
    <>
      <nav>
        <ul className="nav navbar-nav nav-links">
          <li key="home"><NavLink to="/">Home</NavLink></li>
          <li key="about"><NavLink to="/about">About</NavLink></li>
          <li key="page2"><NavLink to="/shabbat">Shabbat</NavLink></li>
          <li key="page3"><NavLink to="/holidays">Holidays</NavLink></li>
        </ul>
      </nav>
    </>
  );
}

/*

const Toggl = () => {
  return (
    <div className="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
  );
}  
*/

export default Nav;
