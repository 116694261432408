import React from 'react';

const About = (props) => {
  return (
    <div className="container">

      <h1>About</h1>
      <h4>Jew<span className="blue">day</span>ism.com is a React project made by <a href="https://andrearogoff.com/" target="_blank" rel="noreferrer">Andrea Rogoff</a>.</h4>

      <blockquote cite="https://www.hebcal.com/">Jewish holidays and candle-lighting times are provided by Hebcal.com with a CC BY 4.0 International License.</blockquote>


    </div>
  );

}

export default About