import React from 'react';
import dateFormat from 'dateformat';







const HolidayButton = props => {
  return (

    <a className="learn-more" onClick={props.onClick}>
      Learn more 
    </a>

  );
}

const Date = props => {
  return (
    dateFormat(props.date, "dddd, mmmm d")
  );
};

const Year = props => {
  return (
    dateFormat(props.date, "yyyy")
  );
};


class Holidays extends React.Component {
  state = {
    posts: []
  };
 

  componentDidMount() {
    fetch("https://www.hebcal.com/hebcal?v=1&cfg=json&maj=on&min=on&mod=on&nx=on&year=now&month=x&ss=off&mf=on&c=on&M=on&s=on")
      .then((response) => response.json())
      .then((posts) => {
        if (Array.isArray(posts)) {
          this.setState({ posts });
        } else if (typeof posts === "object") {
          this.setState({ posts: [posts] });
        }
      })
      .catch((error) => console.error(error));
  }
  render() {
    


    
    return (
      
      
      <div className="container">
         

            {this.state.posts.map((post) => (
              
                  <>

                    <h1>Holidays <Year year={post.date}/></h1>

                      {post.items.map(({title, hebrew, date, hdate, memo, link, index}) => (
                      <div key={index}>
                        <h3 className="api-title">{title} <span> - {hebrew}</span></h3>
                        <h4><Date date={date}/><span> ({hdate})</span></h4>
                        <p className="memo">{memo}</p>

                      {link ? <a href={link} target="_blank" rel="noreferrer"><HolidayButton torah={link} /></a>: <div></div>}

                      
                      </div>
                    )
                    )}
 
                    
                                    
 


              </>

        ))}


      </div>
    );
  }
}

export default Holidays