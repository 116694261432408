import React from 'react';

import './index.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';



const app = document.getElementById('app');

// create a root
const root = createRoot(app);

//render app to root
root.render(<App />);


reportWebVitals();