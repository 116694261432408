import React from 'react';
import dateFormat from 'dateformat';


const TorahButton = props => {
  return (
    <a className="learn-more" onClick={props.onClick}>
      Read Prayer
    </a>
  );
}

const Date = props => {
  return (
    dateFormat(props.date, "dddd, mmmm d")
  );
}



class Shabbat extends React.Component {
  state = {
    posts: []
  };
 

  componentDidMount() {
    fetch("https://www.hebcal.com/shabbat?cfg=json&zip=92119&M=on")
      .then((response) => response.json())
      .then((posts) => {
        if (Array.isArray(posts)) {
          this.setState({ posts });
        } else if (typeof posts === "object") {
          this.setState({ posts: [posts] });
        }
      })
      .catch((error) => console.error(error));
  }
  render() {
    


    
    return (
      
      
      <div className="container">
         
         <h1>Shabbat</h1>
            {this.state.posts.map((post) => (
              
                  <>
                    <h2>This week in {post.location.city}:</h2>
                  

                      {post.items.map(({title, date, link, index}) => (
                      <div key={index}><h3 className="api-title"><Date date={date}/></h3><h4>{title}</h4>

                      {link ? <a href={link} target="_blank" rel="noreferrer"><TorahButton torah={link}/></a>: <div></div>}

                      
                      </div>
                    )
                    )}
 
                    
                                    
  


              </>

        ))}


      </div>
    );
  }
}


export default Shabbat