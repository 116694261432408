import React from "react";


const Header = () => {
  return (
    <header className="container">
      <h1 className="hero">Jew<span className="blue">day</span>ism</h1>
      <h3 className="tagline">Celebrate Tradition Every Day</h3>
    </header>
  );
}








export default Header;