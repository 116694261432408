import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Nav from "./components/Nav";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import About from "./pages/About";
import Holidays from "./pages/Holidays";
import Shabbat from "./pages/Shabbat";








const App = () => {

  return (


    <Router>
      
      <div>
      
      <Nav/>
      <Header/>



        <Routes>
          <Route exact path="/" element = {<Home />} />
          <Route path="/about" element = {<About />} />
          <Route path="/shabbat" element = {<Shabbat />} />
          <Route path="/holidays" element = {<Holidays />} />
          <Route path="/notfound" element = {<NotFound />} />
          <Route component={<Nav/>} />
          <Route component={<Header/>} />
          <Route component={<Footer/>} />


        </Routes>
        <Footer />
      </div>
    </Router>
  );
}


export default App;
