import React, { Component } from 'react';
import dateFormat from "dateformat";
import { Link } from "react-router-dom";




const Home = () => {
  return (

    <div className="container">
      <Today />
      <h4>Let's check the calendar for <Link to="/Shabbat">Shabbat</Link> times and <Link to="/Holidays">Holidays</Link>.</h4>


    </div>

  );
}

const now = new Date();
/*
const Gregorian = () => {
  return (
   <span > {dateFormat(now, "mmmm dd, yyyy")}</span>

  );
}
*/



const textArray = ['Shabbat', 'Rosh Hashana', 'Pesach', 'Chanukah', 'Purim', 'Tu BiShvat', 'Sukkot' ];

class Today extends Component {
  constructor() {
    super();
    this.state = { textIdx: 0 };
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 1500);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    let textThatChanges = textArray[this.state.textIdx % textArray.length];

    return (
      <section>
        <h1 className="celebrate">When do we celebrate <span className="blue">{textThatChanges}</span>?</h1>
      </section>
    )
  }
}
  













export default Home