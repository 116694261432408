import React from "react";
//import dateFormat from "dateformat";




const Footer = () => {
  return (
    <footer className="container">
      <h4>Every <span className="blue">day</span> is a celebration.</h4>
      <ColorLine  />
       <small>Copyright © {new Date().getFullYear()}  Andrea Rogoff</small>
    </footer>
  );
}




const ColorLine = () => (

    <hr  style={{
        height: .5,
        borderColor:'#000000',
        marginTop: '0px',
        marginBottom: '5px',
    }}/>

);


const now = new Date();


/*

const Gregorian = () => {
  return (
   <span > {dateFormat(now, "mmmm dd, yyyy")}</span>

  );
}


class HomeDate extends React.Component {
  state = {
    posts: []
  };
 

  componentDidMount() {
    fetch("https://www.hebcal.com/converter?cfg=json")
      .then((response) => response.json())
      .then((posts) => {
        if (Array.isArray(posts)) {
          this.setState({ posts });
        } else if (typeof posts === "object") {
          this.setState({ posts: [posts] });
        }
      })
      .catch((error) => console.error(error));
  }


  render() {

    return (

      <div>
            {this.state.posts.map((post) => (
                  <>

                   <p>  <strong>Every <span className="blue">day</span> is a celebration...</strong> <Gregorian /><span className="blue"><strong> | </strong></span>{post.hy} {post.hm} {post.hd}<span className="blue"><strong> | </strong></span>{post.hebrew}</p>


              </>

        ))}

      </div>
    );
  };
}
  */








export default Footer;